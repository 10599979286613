// Here you can add other styles
.fw-bold {
  font-weight: 700;
}

.fw-normal {
  font-weight: 400 !important;
}

.modal-calender .container {
  display: flex;
  line-height: 2rem;
  color:#797979;
}
.modal-calender .container .icon{
  margin: 0 1rem 0 0;
}
.modal-calender .container .date{
  font-size:0.7rem;
  margin:-0.5rem 0 0 0;
}
.modal-calender .container .content .title{
  font-size: 1.2rem;
  font-weight: 600;
}
.modal-calender .container .content .description{
  min-height: 3rem;
}
.modal-calender .action button{
  margin:0 0.2rem 1rem 0.2rem;
}
.google-button {
  height: 35px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0,0,0,0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    box-shadow: 1px 4px 5px 1px rgba(0,0,0,0.1);
  }

  &:active {
    background-color: #e5e5e5;
    box-shadow: none;
    transition-duration: 10ms;
  }
}
.container-logo{
  text-align: center;
  width: 100%;
}
.logo-app {
  width: 80px;
}
.no-selection{
  max-width: 100%!important;
  transform:scale(0.77)!important;
  -webkit-transform:scale(0.77)!important;
  transform-origin: center center!important;
  -webkit-transform-origin: center center!important;
}
.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
}

.job-card {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin:0.5rem 0.2rem;
}
.card{
  border: none!important;
}

.Toastify__toast-container--top-right {
  top: 4rem!important;
}
.forgot{
  position: absolute;
  bottom: 8px;
  right: 16px;
  cursor: pointer;
}
.job-card .contact-img {
  max-width: 100px;
  height: 80px;
  padding: 15px;
  text-align: center;
  font-weight: 700;
  border-radius: 10%;
  background-color: #1cabe6;
  color: #fff;
}
.job-card-title {
  font-weight: 600;
  margin-top: 16px;
  font-size: 14px;
  text-transform: uppercase;
}
.job-card-subtitle {
  color: #ACA3AC;
  font-size: 13px;
  margin-top: 14px;
  line-height: 1.6em;
}
.job-card-header {
  display: flex;
  align-items: flex-start;
}

.job-overview-card + .job-overview-card {
  margin-top: 20px;
}

.overview-card .job-overview-buttons .search-buttons.time-button{
  background-color: #E6EEF9;
  color: #1cabe6;
}
.overview-card .job-overview-buttons .search-buttons.level-button {
  background-color: #E6EEF9;
  color: #1cabe6;
}
.overview-detail .job-card-title,
.overview-detail .job-card-subtitle {
  margin-top: 4px;
}
.overview-detail .job-card-subtitle {
  font-size: 12px;
  font-weight: 500;
}

.job-overview-buttons {
  // display: flex;
  max-width: 100%;
}

.job-overview-buttons button {
  margin-top:-1.7rem;
  right:0;
}
.job-overview-buttons .search-buttons {
  background-color: #F0F0F0;
  max-width: 90px;
  display: flex;
  font-size: 11px;
  padding: 6px 8px;
  margin-top: 0;
  font-weight: 500;
}
.job-overview-buttons .search-buttons.time-button {
  color: #1cabe6;
  margin-right: 8px;
}
.job-overview-buttons .search-buttons.level-button {
  color: #1cabe6;
}
.job-overview-buttons .job-stat {
  color:#1cabe6;
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;
}
//Breadcrumb
.breadcrumb a {
  color:#1cabe6!important;
}
button:focus {
  outline: none;
  outline: none;
}
.btnDownload-doc{
  max-width: 15px;
  cursor: pointer;
}
.btnDownload-Application{
  max-width: 15px;
  cursor: pointer;
}
.download-doc{
  margin: 0.2rem;
  font-weight: 500;
}
.btnDownload{
  max-width: 20px;
  cursor: pointer;
}
.app-header .navbar-toggler{
  z-index: 1000;
}
.app-header .navbar-brand {
  // width: 50px;
  // height: 50px;
  // margin-left: 50px!important;
  width: 155px;
  height: 55px;
}
.breadcrumb {
  position: relative;
  border-radius: 0;
  border-bottom: none;
}
.breadcrumb {
  background-color: transparent;
  // padding: 1rem 0 0 0;
  margin-bottom: 0rem;
}

//------------
html,
body {
  font-family: 'Rubik', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app{
  background-color: #ffffff!important;
}
.app-body {
  overflow-x: visible!important;
  background-color: #f6f6f6!important;
}

.main {
  background-color: #f6f9ff;
}
// .main .container-fluid {
//   padding: 5rem 15px 15px 15px ;
// }
//QuickSearch Credit Score
.quick-search label {
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(80, 80, 80, 0.65);
}
.quick-search p{
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #505050;
}
//Detail Credit Score
// .card-credit-score{
//     padding: 23px 32px 35px;
//     border-radius: 12px;
//     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
//     border: solid 1px rgba(223, 223, 223, 0.5);
//     background-color: #fff;
// }
.card-credit-score .name{
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e53935;
}
.card-credit-score .name-contract{
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(80, 80, 80, 0.5);
}
.card-credit-score .text{
  margin-top: 1rem;
  font-size: 0.7rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #505050;
}


.line-bottom-credit{
  border-bottom: 1px solid rgba(80, 80, 80, 0.5);
}
.box{
  width: 5rem;
  height: 5rem;
  margin-top: 1rem;
  text-align: center;
  border-radius: 5px;
  border: solid 1px rgba(80, 80, 80, 0.5);
}
.whites i{
  margin-top: 1rem;
  font-size: 3rem;
  color: rgba(80, 80, 80, 0.5);
}
.text-red{
  color: #e53935;
}
.red i{
  margin-top: 1rem;
  font-size: 3rem;
  color: #fff;
}
.box label{
  margin-top: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.red{
  background-color: #e53935;
  color: #fff;
}
.green{
  background-color: #00af23;
  color: #fff;
}
.yellows{
  background-color: #afa900;
  color: #fff;
}
.whites{
  background-color: #fff;
}
.whites label{
  color:  rgba(80, 80, 80, 0.5);
}
.tab-content{
  background-color: transparent!important;
  border: none!important;
}
.tab-content .tab-pane{
  padding: 1rem 0 1rem 0;
}
.nav-tabs .nav-link{
  padding: 0.8rem ;
  font-size: 0.8rem;
  font-weight: bold;
  color: #505050;
}
.nav-tabs .nav-link.active:hover {
  color: #fff;
  background-color: #005274;
  border-color: #fff;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #005274;
  border-color: #fff;
}
.nav-tabs {
  background-color: #fff;
}
.pay-date{
  font-size: 0.55rem;
  font-weight: 600;
}
.credit-title{
  font-size: 1.2rem;
  font-weight: 700;
}
.credit-sub-title{
  font-size: 1rem;
  font-weight: 700;
  color: #e53935;
}
.pi-label{
  background-color: #dfdfdf;
  color: #505050;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
  padding: 0.3rem 1rem 0.3rem 1rem;
}
.pi-co-label{
  background-color: #f7f7f7;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 0.8rem;
  color: #505050;
  padding: 0.3rem 1rem 0.3rem 1rem;
}

//animation login
// .animation{
//   position: relative;
//   width: 100%;
//   background-color: #fff;
//   min-height: 200px;
//   overflow: hidden;
// }

// .animation:before {
//   content: "";
//   position: absolute;
//   height: 600px;
//   width: 400px;
//   right: 40%;
//   transform: translateY(-50%);
//   background-image: linear-gradient(80deg, #1cabe6 0%, #1cabe6 90%);
//   transition: 1.8s ease-in-out;
//   border-radius: 50%;
// }
//font
.f-gray{
  font-size: 0.9rem;
  color: #868686dc;
  font-weight: 600;
}
//icon-gambar
.i-large{
  font-size: 4rem!important;
}
.wh-large{
  width: 6rem!important;
  height: 6rem!important;
}
.close-modal{
  margin-top: -1rem!important;
  cursor: pointer;
}
.icon-app{
  max-height: 150px;
  max-width: 140px;
  margin-top: -100px;
  z-index: 2029;
}
.sidebar .nav {
  z-index: 2029;
}
.header-fixed .app-header {
    z-index: 10;
}
.notfound{
  margin-top: 2rem;
  font-size: 1rem;
  color: #1cabe6;
  font-weight: 500;
}
.ant-message{
  z-index: 9999999!important;
}
//Background
.bg-login {
  background: url('../assets/login-bg.jpg');
  background-repeat: no-repeat;
}
.navy{
  background-color: #005274!important;
  color:#ffffff;
  font-weight: 500;

}
.b-bottom-none{
  border-bottom: none!important;
}
.bg-transparent{
  color:#005274!important;
  background-color: #ffffff00!important;
}
.bg-primary{
  color:#ffffff!important;
  background-color: #1cabe6!important;
}
.c-primary{
  color: #1cabe6!important;
  fill: #1cabe6!important;
}
.c-green{
  color: #13e500!important;
  fill: #13e500!important;
}
.c-red{
  color: #c50000!important;
  fill: #c50000!important;
}
.yellow{
  font-weight: 700;
  font-size: 0.7rem;
  height: 1.6rem;
  padding: 0px;
  width: 4rem;
  border-radius: 10px;
  background-color: yellow!important;
  color: black!important;
}
.btn-ghost-white{
  color: #707070;
  background-color: #ffffff;
  border-color: #ffffff;
}
.bg-gray{
  background-color: #c9c9c9!important;
  color: #0e0e0e;
}
.bg-white{
  background-color: #FFFFFF!important;
}
//Border
.bd-primary{
  border-color: #1cabe6;
}
//spiner
.sk-three-bounce{
  height: 1px!important;
}
// [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
// .site-collapse-custom-collapse .site-collapse-custom-panel {
//   margin-bottom: 24px;
//   overflow: hidden;
//   background: #f7f7f7;
//   border: 0px;
//   border-radius: 2px;
// }
//Button
.btn-white {
  color: #1cabe6 !important;
  background-color: #ffffff !important;
  border-color: #1cabe6 !important;
}
.btn-white:hover {
  color: #ffffff !important;
  background-color: #1cabe6 !important;
  border-color: #ffffff !important;
}
.c-white {
  color: #ffffff!important
}
.orange {
  color: #ffffff!important;
  background-color: #005274 !important;
  border-color: #005274 !important;
}
.orange:hover {
  color: #ffffff!important;
  background-color: #01425e !important;
  border-color: #01425e;
}
.white {
  color: #000000!important;
  background-color: #ffffff;
  border-color: #dfdfdf;
}
.white:hover {
  color: #000000!important;
  background-color: #dfdfdf;
  border-color: #ffffff;
}
.card-target{
  height: 340px;
}
//button
.btn-pill-square{
  margin:1rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.fa-lg {
  font-size: 2rem!important;
  line-height: .75em;
  justify-content: center;
  padding: 0.5rem 0 0 0;
}
.blue_primary {
  color: #1cabe6 !important;
}
.blue_dark{
  color: #005274!important;
}
.black{
  color: #232425!important;
}
.required{
  color: #df0000;
  display: inline-block;
}
.pointer{
  cursor: pointer;
}
.btn-black {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}
.btn-dark {
    color: #fff;
    background-color: #005274;
    border-color: #005274;
}
.ant-btn-primary{
  background: #1cabe6!important;
  border-color: #1cabe6!important;
}
.f-j-sm{
  font-size: 0.8rem;
}
.f-sm{
  font-size: 0.7rem;
}
//step antdesign
.ant-upload-list{
  display: none;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #005274!important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #005274!important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #005274!important;
}
.ant-steps-item-finish .ant-steps-item-icon  .ant-steps-icon {
  color: #005274!important;
}
.steps-content {
  min-height: 400px;
  margin-top: 16px;
  padding: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 0.5rem;
}

.steps-action {
  width: 100%;
  margin: 1rem 0 0 0;
}
.steps-action .prev{
  float: right;
  width: 100%;
}
.steps-action .next{
  float: left;
  width: 100%;
}
.steps-action .steps-num{
  font-size: 0.6rem;
  margin-top: -1rem;
  color: #A8A8A8;
}
.steps-action .steps-sub{
  font-size: 1.2rem;
  color:  #000000;
  font-weight: 700;
}
.steps-action .steps-desc{
  font-size: 0.8rem;
  font-weight: 600;
  color: #A8A8A8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #000000!important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #000000!important;
}
.ant-steps-item-content{
  width: 11.5rem!important;
  text-align: left;
}
.ant-steps-item-tail{
  width: 100%!important;
}
.ant-steps-item{
  max-width: 8.6%!important;
}
.ant-steps-item-title{
  font-size: 0.8rem!important;
  width: 5rem!important;
  font-weight: 600;
}
.ant-steps-item-container{
  width: 7rem!important;
}
//Detail Profil
.profile .name{
  font-weight: 500;
  font-size: 23px;
  color: #707070;
}
.profile .btn-profile{
  font-weight: 500!important;
  color: #707070;
}
.profile-detail .fullName{
  font-weight: 700!important;
  color: #141414;
}
.form-profile{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background: white;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.profile-detail .note-profil{
  background: white;
  min-height: 200px;
  padding: 1rem;
  border-radius: 5px;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 20px;
}
.label-upload{
  cursor: pointer;
  color: #59B2E7;
  font-weight: 500;
}
//date picker
.ant-picker {
  width: 100%!important;
}
//card loan
.card-advisor {
  cursor: pointer;
  position: relative;
  background: #fff;
  max-height: 400px;
  transition: all .3s;
}
//card requirement

.card-req{
  margin-bottom: 0.5rem;
}
.card-req .card-header{
  height: 3rem;
  background: #DFDFDF;
}
.card-req .card-header .req-name{
  margin-top: 0.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: white;
  font-size: 12px;
  font-weight: 700;
}
.card-req .card-header .req-names{
  margin-top: 0.6rem;
  color: white !important;
  font-size: 12px;
  font-weight: 700;
}
.card-req .card{
  background: #005274;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  height: 5rem;
  max-width: 6rem;
  width: 100%;
  margin: 0 auto;
  padding: 0.7rem  0.4rem 0.5rem 0.4rem;
  position: relative;
}
.rounded-circle{
  margin-top: 1rem;
}
.card-req .card .cls-1{
  fill: #ffffff!important;
  stroke: #ffffff!important;
}
.card-req .card-header .edit{
  font-weight: 700;
  color: #000000;
}
.detail-contact .scroll{
  overflow-y: scroll;
  max-height: 500px;
}

.scroll {
  overflow-y: scroll;
  max-height: 500px;
}
.padding-bo{
  padding: 0.7rem!important;
}
.line-blue{
  border: 0.8px solid #1cabe6!important;
}
.line-bottom-docs{
  border-bottom: 1px solid #dfdfdf;
}
.line-bottom-blue{
  border-bottom: 1px solid #1cabe6!important;
}
.line-bottom{
  padding-bottom: 2rem;
  border-bottom: 1px solid #dfdfdf;
}
.line-right{
  padding-right: 2rem;
  border-right: 1px solid #dfdfdf;
}
.line-h {
  background-color: #01425e;
  width: 1px;
  height: 60px;
  padding: auto 0px;
}
.card-req .req-body{
  cursor: pointer;
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
}
.card-req .req-body .req-isi .product-detail{
  background-color: #ffffff;
  border:1px solid #ebebeb;
}
.card-req .req-body .req-isi{
  font-size: 0.8rem;
  background-color: #ffffff;
  font-weight: 600;
  color: #797979;
}
.card-req .req-body .req-isi .step{
  color: #1cabe6;
  font-weight: 600;
}
.card{
  overflow: hidden;
  transition: all 0.25s;
}
.card-req,
.card-req .card-header {
   background-color: #dfdfdf;
   border-color: #dfdfdf;
}
// .active-contact{
//   cursor: pointer;
//   margin: 0.3rem 0rem;
//   min-height: 6rem;
//   border-radius: 20px;
//   border: solid 1px #dfdfdf;
//   background-color: #fff
// }
.active-contact .logo{
  display: flex;
  margin: auto;
  justify-content: center;
}
.active-contact .logo .sub-logo{
  display: flex;
  margin: auto;
  justify-content: center;
}
.active-contact .logo .btn-active{
  text-align: center;
}
.active-contact .logo .btn-active button{
  font-size: 0.5rem;
}
.active-contact .list{
  padding: 0.4rem;
}
.active-contact .list-access{
  padding: 0.4rem;
}
.active-contact .list .name{
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}
.active-contact .list div{
  font-size: 0.7rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.7px;
  text-align: left;
  color: rgba(80, 80, 80, 0.8);
}

.active-contact .list-access .name{
  font-size: 0.7rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 1.1rem;
  color: rgba(0, 0, 0, 0.8);
}
.active-contact .list-access div{
  font-size: 0.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.7px;
  text-align: left;
  color: rgba(80, 80, 80, 0.8);
}
.active-contact .remove{
  font-size: 0.6rem;
  margin: 2rem 0 0 0;
  float: right;
}
.active-contact-adviser{
  cursor: pointer;
  margin: 0.3rem 0rem;
  min-height: 10rem;
  border-radius: 8px;
  border: solid 1px #dfdfdf;
  background-color: #fff
}
.active-contact-adviser .logo{
  display: flex;
  margin: auto;
  justify-content: center;
}
.active-contact-adviser .logo .sub-logo{
  display: flex;
  margin: auto;
  justify-content: center;
}
.active-contact-adviser .logo .btn-active{
  text-align: center;
}
.active-contact-adviser .logo .btn-active button{
  font-size: 0.5rem;
}
.active-contact-adviser .list{
  padding: 0.4rem;
}
.active-contact-adviser .list-access{
  padding: 0.4rem;
}
.active-contact-adviser .list .contact{
  text-align: center;
  font-size: 0.7rem;
  margin-top:0.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.8);
}
.active-contact-adviser .list .name{
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.8);
}
.active-contact-adviser .list div{
  font-size: 0.7rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.7px;
  text-align: left;
  color: rgba(80, 80, 80, 0.8);
}

.active-contact-adviser .list-access .name{
  font-size: 0.7rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 1.1rem;
  color: rgba(0, 0, 0, 0.8);
}
.active-contact-adviser .list-access div{
  font-size: 0.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.7px;
  text-align: left;
  color: rgba(80, 80, 80, 0.8);
}
.active-contact-adviser .remove{
  font-size: 0.6rem;
  margin: 2rem 0 0 0;
  float: right;
}
.avatar-req {
   width: 3rem;
   height: 3rem;
   border-radius: 50%;
   border: 1px solid #198cff;
   background: #f8f9fa;
   color: #fff;
   margin-right: 0.75rem;
   border-color: #7979798e;
}
.avatar-icon {
   width: 2.5rem;
   height: 2.5rem;
   border-radius: 50%;
   border: 2px solid #ffffff;
   background: #f8f9fa;
   color: #fff;
   margin-right: 0.75rem;
   border-color: #ffffff;
}

.avatar-profil {
  width: 5rem!important;
  height: 5rem!important;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: #f8f9fa;
  color: #fff;
  margin: auto;
  border-color: #ffffff;
}
.card-progress{
  margin: 1rem 0 0 0;
  height: 300px;
}
.card-progress .headers{
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
}
.card-progress .card-pro{
  max-width: 50%;
  margin: 1rem 0 0 25%;
}
.form-check{
  padding-left: 0rem!important;
}
.form-check-label{
  width: 100%;
}
//card-detail-office
.card-detail-office .description .isi-header{
  margin: 0.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: #1cabe6;
}
.card-detail-office .avatar-office{
  text-align: center;
}
.card-detail-office .description .isiApp,
.card-detail-office .description .isi{
  margin: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: #666666;
}
.card-detail-office .description .isi span{
  font-size: 0.9rem;
  font-weight: 600;
  color: #A8A8A8;
}
.card-detail-office .description .isiApp span{
  font-size: 0.9rem;
  font-weight: 600;
  color: #1cabe6;
}
.font-doc{
  font-size: 0.9rem;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.please-wait{
  font-size: 1rem;
  color: #1cabe6;
}
//dashboar
.ReactModal__Overlay{
  z-index: 1020!important;
}
.lines{
  border-right: solid 1px #cecece;
}
.line{
  border-right: solid 1px #cecece;
}
.das-profil .left .img-avatar {
  margin: 8% 0 8% 0;
  width: 69px;
}
.das-profil .welcome{
  display: block;
  margin:0rem 0 0 0!important;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(80, 80, 80, 0.5);
}
.das-profil .name{
  display: block;
  margin-bottom: 0!important;
  font-size:20px;
  font-weight: 500;
}
.das-profil .location{
  margin-bottom: 0!important;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
// .das-profil .location i{
//   color: #505050;
//   font-weight: bold;
// }
.das-profil .sides{
  margin-top: 1rem;
}
.das-profil .email,
.das-profil .phone {
  padding-left: 1rem;
  margin-bottom: 0 !important;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
// .das-profil .email i,
// .das-profil .phone i{
//   color: #505050!important;
// }
.das-profil .btn{
  margin: -0.5rem 0 0 0!important;
}
.das-profil {
  padding:0.5rem 1.3rem;
}
.das-profil .text-bar{
  font-size: 2rem;
  font-weight: 500;
  color: #000000;
}
.card-dashboard .params{
  padding: 4rem;
}
.das-profil .headers{
  color: #000000!important;
}
.das-profil .text-label{
  font-size: 0.8rem;
  font-weight: 600;
  color: #000000;
  margin: 1rem 0 0 0;
}

  .das-profil .edit-target{
    margin:0.5rem!important;
    position: absolute;
    right: 0;
    top: 0;
  }
.maxCalender{
  max-height: 15.5rem!important;
}
.update{
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  //Calndr
}
.container-calender{
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar{
  border:none!important;
  max-width:270px!important;
}
.react-calendar__navigation__label span,
.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button{
  color: #A8A8A8!important;
  font-weight: 700!important;
}
.react-calendar__month-view__weekdays__weekday{
  color: #1cabe6!important;
  font-weight: 700!important;
}
.react-calendar__tile--active {
  background: #1cabe6!important;
  color: white;
  border-radius: 50%;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button{
  display: none!important;
}
// .das-profil .calender{
//   width: 100%!important;
//   border: none;
//   cursor: pointer;
//   text-align: center;
//   height: 100%;
//   margin: auto;
//   padding: 1rem;
// }
// .das-profil .calender{
//   font-size: 1rem;
//   padding: -1rem;
// }
// .das-profil .calender label{
//   font-size: 0.9rem;
//   font-weight: 700;
//   z-index: 99;
//   margin: 0 -1rem 0 auto;
//   color: #1cabe6;
// }
// .das-profil .calender p{
//   font-size: 0.9rem;
//   z-index: 99;
//   font-weight: 700;
//   margin: 5rem -1rem 0 auto;
//   color: #1cabe6;
// }
// .das-profil .calender .active{
//   margin-top: -2rem;
//   border-radius: 1rem;
//   height: 12rem;
//   cursor: pointer;
//   background-color: #1cabe6;
//   color: #ffffff!important;
// }

// .das-profil .calender .deactive label{
//   margin: -1rem;
// }
// .das-profil .calender .deactive p{
//   margin: 3rem -1rem;
// }
// .das-profil .calender .deactive:hover{
//   margin-top: -2rem;
//   border-radius: 1rem;
//   height: 12rem;
//   cursor: pointer;
//   background-color: #1cabe6;
//   color: #ffffff!important;
//   z-index: 9999;
// }

// .das-profil .calender .deactive:hover label{
//   margin: 2.2rem -1rem;
//   color: #ffffff;
// }
// .das-profil .calender .deactive:hover p{
//   margin: 3rem -1rem;
//   color: #ffffff;
// }
// .das-profil .calender .active:hover{
//   margin-top: -2rem;
//   border-radius: 1rem;
//   height: 12rem;;
//   background-color: #00ace527;
//   color: #ffffff!important;
//   z-index: 9999;
// }
// .das-profil .calender .active:hover label,
// .das-profil .calender .active:hover p{
//   color: #1cabe6!important;
// }
// .das-profil .calender .active label{
//   margin: 2.2rem -1rem;
//   color: #ffffff;
// }
// .das-profil .calender .active p{
//   margin: 3rem -1rem;
//   color: #ffffff;
// }

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #005274;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #005274;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005274;
}
.bold{
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8)
}
.table-user-add .list-group-item {
  background-color: none!important;
  border:  none!important;
}
.table-app{
  min-width: 1000px;
}
.table-app td{
  cursor: pointer;
}
.table-app tr th,
.table-app tr td{
  color: #505050;
}
.hide-pdf{
  display: none;
}
.maxHeight-10{
  max-height: 20rem;
  height: 100%;
  overflow-y: scroll;
}
.maxHeight{
  max-height: 21.5rem;
  height: 100%;
  padding: 1rem 0 1rem 0;
}
.card-dashboard-bank{
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
  background: #fff;
  font-weight: 700;
  color: #505050;
  border-radius: 8px;
  transition: all .3s;
  border: none;
  border: solid 1px #dfdfdf!important;
  // box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}
.card-dashboard-bank:focus{
  background: #005274;
  color: #fff;
}
.card-dashboard-bank:hover{
  background: #005274;
  color: #fff;
}
.card-dashboard-bank .nav-icon{
  color: #000000;
}

.card-dashboard-bank Icon_akad{
  max-width: 0.3rem!important;
}
.card-dashboard-bank:focus .img{
  color: #fff;
}
.card-dashboard-bank:hover .img{
  color: #fff;
}
.card-dashboard-bank .names{
  text-align: left;
}
.bank .ongoing{
  font-size: 1rem;
  font-weight:700 ;
  margin-bottom: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.bank .text{
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(80, 80, 80, 0.5);
}
// .card-dashboard{
//   cursor: pointer;
//   position: relative;
//   background: #fff;
//   max-height: 400px;
//   border-radius: 20px;
//   transition: all .3s;
//   border: solid 1px #dfdfdf!important;
//   // box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
// }
.card-dashboard .names{
  color: #000000;
  margin-top: -0.6rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
}
.card-dashboard .text-primary{
  color: #000000!important;
}
.card-dashboard .number{
  margin-top: -0.7rem;
  font-size: 0.7rem;
  color: #000000!important;
}
.card-dashboard .notification p{
  font-size: 1.1rem;
  color: #1cabe6!important;
  text-align: center;
  font-weight: 600;
}
.card-dashboard .headers{
  font-size: 1rem;
  color: #A8A8A8;
  font-weight: 600;
  margin-bottom: -2rem;
}

// .notification .sub-card{
//   cursor: pointer;
//   margin-top: -0.3rem;
//   border-radius: 0.6rem;
//   padding: 0.5rem 1rem;
//   position: relative;
//   background: #fff;
//   max-height: 400px;
//   transition: all .3s;
//   box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
// }
.notification p{
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
// .notification .sub-card:hover{
//   background: #00ace527;
// }
.notification .sub-card i{
  font-size: 1.4rem;
  color: #1cabe6!important;
}
.notification .sub-card .group{
  font-size: 0.8rem;
  color: #000000!important;
  font-weight: 600;
}
.notification .sub-card .action{
  font-size: 0.6rem;
  color: #747474!important;
  font-weight: 600;
}
.notification .hour{
  text-align: left;
  font-size: 0.6rem;
  color: #747474!important;
  font-weight: 600;
}
.notification .body-notif{
  cursor: pointer;
  height: 50px!important;
  border-bottom: 1px solid #f0f0f0;
  padding: 0.5rem 0
}
.notification .body-notif:hover{
  background:#1cabe6;
  border-bottom: 1px solid #1cabe6;
}
.notification .body-notif .group{
  font-size: 0.7rem;
  color: #000000!important;
  font-weight: 600;
}
.notification .body-notif .action{
  font-size: 0.6rem;
  color: #747474!important;
  font-weight: 600;
}
.notification .body-notif:hover .hour{
  color: #ffffff!important;
}
.notification .body-notif:hover .group{
  color: #ffffff!important;
}
.notification .body-notif:hover .action{
  color: #ffffff!important;
}
.see-all{
  border: none!important;
  position: relative;
  width: (100%);
  padding: none;
  left: 0;
  text-align: center;
  color: #000000!important;
}
.see-all span{
  font-weight: 500;

}
.notification-all .sub-card{
  cursor: pointer;
  margin-top: -0.3rem;
  border-radius: 0.6rem;
  padding: 1rem 1rem;
  position: relative;
  background: #fff;
  max-height: 400px;
  transition: all .3s;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
}

.notification-all  .group{
  font-size: 0.7rem;
  color: #000000!important;
  font-weight: 700;
}
.notification-all  .action{
  font-size: 0.6rem;
  color: #747474!important;
  font-weight: 600;
}
.notification-all  .hour{
  display:inline;
  text-align: left;
  font-size: 0.6rem;
  color: #747474!important;
  font-weight: 600;
}
.notification  .close,
.notification-all  .close{
  min-width: 20px!important;
  display:inline;
  width: 20px!important;
  height: 20px!important;
  z-index: 999999;
}
.notification  .close svg,
.notification-all  .close svg{
  color: #000000;
  width: 10px;
  margin: -1rem 0 0 0;
  z-index: 999999;
}
.menu-logout{
  min-width: 50px;
  text-align: center;
}
.menu-logout .title{
  font-size: 0.7rem;
  float: left;
}
.menu-logout .body-menu{
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  padding: 0.5rem 0;
  margin: 0 1rem;
  min-width: 200px;
}
.menu-logout .body-menu span svg{
  margin-top: -0.5rem;
}
.body-menu:hover{
  background:#1cabe6;
  border-bottom: 1px solid #1cabe6;
}
// .ant-popover-inner-content{
//   padding-bottom: 0px!important;
// }
.ant-popover {
  min-width: 15rem;
}
//badge
.badge-white {
  color: #1cabe6!important;
  background-color: #f0f3f5;
}
.containers{
  margin: 0 2rem;
}
.btn-edit{
  margin: 0 3rem 0 0;
}
.font-title{
  font-size: 13px;
  color: #737373;
  font-weight: 700;
}
.font-product label{
  font-size: 12px;
  color: #767676!important;
  font-weight: 500;
}
.card-pro .name div{
  top: 50%;
  margin-bottom: auto;
}
.card-pro .name{
  font-size: 1.2rem;
  font-weight: 600;
}
.card-pro .detail i{
  color: #1cabe6;
}
.card-pro .detail span{
  color: #737373;
}
.card-pro .process label{
  color: #1cabe6;
  font-size: 0.8rem;
  font-weight: 500;
}
#pageDropDown{
  display: none;
}
//Pagging
// .page-item.active .page-link {
//     background-color: #A8A8A8;
//     border-color: #A8A8A8;
// }
// .page-link {
//   color: #A8A8A8;
// }
//modal
.modal-header .close {
  opacity: unset;
  background-color: #fff;
  border-radius: 50%;
  width: 1.35rem;
  height: 1.35rem;
  margin: 0rem!important;
  padding: 0rem!important;
}
.modal-header .close span {
  height: 100%;
  width: 100%;
  margin: none;
  padding: none;
  color: #737373;
}
.set-header{
  border-bottom: none;
}
.set-header .modal-title{
  width: 100%;
  text-align: center;
}
// .body-goal .ant-btn{
//   border: 1px solid #000000;
//   background: #ffffff;
//   color:#000000;
//   min-width: 20px!important;
//   width: 20px!important;
//   min-height: 20px!important;
//   height: 20px!important;
// }
// .body-goal .ant-btn .anticon svg{
//   margin-top: -1.3rem;
// }
.body-goal .text{
  margin-bottom: 1rem;
  color:#000000;
}
.set-goals{
  color: #000000;
}
.set-goals .sub-headers{
  padding: 1rem!important;
}
.set-goals .ant-btn-icon-only{
  font-size: 4rem!important;
}
//dropdown filter
.filter-cus{
  max-width: 20rem;
  padding: 1rem!important;
}
.dropdown-filter{
 max-width: 45rem;
 padding: 1rem!important;
}
.dropdown-filter .check{
  font-size: 0.7rem;
  font-weight: 600;
}
.dropdown-filter-bank{
 max-width: 20rem;
 padding: 1rem!important;
}
.dropdown-filter-bank .check{
  font-size: 0.7rem;
  font-weight: 600;
}
//carousel
.react-multi-carousel-item  {
  height: 300px;
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem 0.4rem;
  opacity: 0.5;
}

.react-multi-carousel-item--active {
  z-index: 2;
  opacity: 1;
  padding: 0.5rem 0.4rem;
  position: relative;
}

.react-multi-carousel-dot--active button{
  background:  #1cabe6!important;
}
.min-height{
  min-height: 100px;
}
//card carousel promo

.card-promo {
  border-radius: 1rem;
  height: 250px;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  min-width: 300px;
  color: whitesmoke;
  background-color: whitesmoke;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}
.card-promo:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 0 0;
  transition: all .3s;
  pointer-events: none;
}
.card-promo:hover .title-promo{
  background:none;
}
.card-promo:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%);
  transform: translateY(-50%);
  transition: all .3s;
}

.content-promo {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all .3s;
  z-index: 1;
}
.content-promo > * + * {
  margin-top: 1rem;
}

.title-promo {
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.281);
  font-size: 1.1rem;
  font-weight: bold;
  width: 100%;
  height: 4rem;
}

@media (max-width: 575.98px) {
.react-multi-carousel-item  {
  height: 150px;
  margin-bottom: 3rem;
}
  .card-promo {
    height: 150px;
    margin-left: -5.5rem;
  }
  .title-promo {
    font-size: 0.7rem;
  }
  .date-promo{
    margin-top: -0.3rem!important;
    font-size: 0.5rem!important;
  }
  .btn-promo {
    width: 5rem!important;
    padding: 0.4rem 0.5rem!important;
    font-size: 0.5rem!important;
  }
  .react-multiple-carousel__arrow {
    outline: none!important;
    border-radius: 50%!important;
    min-width: 30px!important;
    min-height: 30px!important;
    opacity: 0.5!important;
  }
  .faq-edit{
    display: none;
  }
 }
 .react-multiple-carousel__arrow {
   outline: none!important;
   border-radius: 50%!important;
   min-width: 30px!important;
   min-height: 30px!important;
   opacity: 0.5!important;
 }
.date-promo{
  padding: 0.5rem;
  font-size: 0.8rem;
  margin-top: -0.5rem;
  position: absolute;
}
.btn-promo {
  cursor: pointer;
  margin: auto;
  width: 8rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: none;
}
.btn-promo:hover {
  color: #fff;
  background-color: #1cabe6;
}
.btn-promo:focus {
  outline-offset: 3px;
}

  .card-promo:after {
    transform: translateY(0);
  }

  .content-promo {
    transform: translateY(calc(100% - 4.5rem));
  }

  .content-promo > :not(.title-promo) {
    opacity: 0;
    transform: translateY(1rem);
    transition: all .3s;
  }

  .card-promo:hover,
.card-promo:focus-within {
    align-items: center;
  }
  .card-promo:hover:before,
.card-promo:focus-within:before {
    transform: translateY(-4%);
  }
  .card-promo:hover:after,
.card-promo:focus-within:after {
    transform: translateY(-50%);
  }
  .card-promo:hover .content-promo,
.card-promo:focus-within .content-promo {
    transform: translateY(0);
  }
  .card-promo:hover .content-promo > *:not(.title-promo),
.card-promo:focus-within .content-promo > *:not(.title-promo) {
    opacity: 1;
    transform: translateY(0);
    transition: all .3s;
  }

  .card-promo:focus-within:before, .card-promo:focus-within:after,
.card-promo:focus-within .content-promo,
.card-promo:focus-within .content-promo > *:not(.title-promo) {
    transition-duration: 0s;
  }

//Card Note

.card-note{
  margin-bottom: 0.5rem;
  border-radius: 10px;
  min-height: 200px;
  overflow: hidden;
  transition: all 0.25s;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}
.card-note:hover {
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}
.card-note::after{
  opacity: 0;
  clip-path: circle(10% at 50% 50%);
  transition: all .25s ease-in;
}
.card-note:hover::after{
  opacity: 1;
  clip-path: circle(100%);
}
.card-note .note-created{
  font-size: 10px;
  color: #ACACAC;
  font-weight: 700;
}
.card-note .note-title{
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: -1rem;
}
.ktp{
  max-width: 20rem!important;
  margin:-2.3rem 0 0 0;
}
.card-note .note-body{
  margin-top: -1.2rem;
  padding: 1rem 1.2rem;
}
.card-note .note-body .note-isi .product-detail{
  background-color: #fffef8;
  border:1px solid #fdf8cc;
}
.card-note .note-body .note-isi{
  font-size: 0.8rem;
  font-weight: 400;
  color: #797979;
}
.card-note,
.card-note .card-header {
   background-color: #F6F6F6;
   border-bottom: none;
   color: #797979;
   cursor: pointer;
}
.card-note .card-header{
  border-radius: 10px;
}

.avatar-note {
   width: 2rem;
   height: 2rem;
   border-radius: 50%;
   border: 1px solid #f8f9fa;
   background: #f8f9fa;
   color: #fff;
   margin-right: 0.75rem;
   border-color: #fff4d3;
}
//Tabs
.ant-tabs-ink-bar {
  background: #1cabe6!important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000!important;
}

//Loan
.avatar-bank{
  border: 1px solid #b4b4b4;
  margin: 1rem 0;
}
.avatar-bank .avatar{
  width: 12rem;
  height: 100%;
}
.avatar-bank .ratings img{
  height: 1.4rem;
  margin: -2rem 0 0 0;
}
.avatar-bank .reviews{
  font-size: 0.7rem;
  color: black;
  font-weight: 500;
  margin: -1rem 0 1rem 0;
}
.card-loan{
  background: #005274;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  height: 6rem;
  width: 6rem;
  margin: 0 auto;
  padding: 0.7rem  0.4rem 0.5rem 0.4rem;
  position: relative;
}
.card-loan .cls-1{
  fill: #ffffff!important;
  stroke: #ffffff!important;
}
.card-loan p{
  color: #ffffff;
  font-size: 0.6rem;
  font-weight: 700;
}
.card-by-cabang{
  border: none!important;
  background: transparent!important;
}
.h-by-cabang{
  min-height: 3rem;
  padding: 1.5rem 1rem;
  border-radius: 10px!important;
  margin: 0.5rem 0.3rem;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: #ffff!important;
  border-bottom: none!important;
}
.h-by-cabang label{
  font-size: 1.1rem;
  font-weight: 600;
}

.b-by-cabang .b-s-cabang{
  position: absolute;
  top:-25px!important;
  z-index: 999999;
  left:-15px;
}
.b-by-cabang .thumbnail{
  border:5px solid rgb(247, 247, 247);
}
.b-by-cabang .sb-avatar--icon,
.b-by-cabang .sb-avatar--text{
  border: none!important;
}
.b-by-cabang .thumbnail span{
  font-size:1.5rem!important;
  font-weight:600!important;
}
.b-by-cabang .c-b{
  background: rgb(247, 247, 247);
  position: relative;
  margin: 2rem 0 2rem 0;
  border-radius: 20px!important;
}
.card-b{
  border: none!important;
  background-color:transparent!important;
}
.card-b .b-name{
  font-size: 1.3rem;
  font-weight: 600!important;
}
.card-b .b-title{
  font-size: 1rem;
  font-weight: 600!important;
  color: #1cabe6;
}
.card-b .b-title span{
  color: #666666;
}
//product
.container-product{
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  grid-gap: 2rem;
  overflow: auto;
  padding: 1rem;

}
.card-product{
  cursor: pointer;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  height: 7rem;
  width: 100%;
  position: relative;
}
.card-product::after{
  content: '';
  background: #005274;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 10px;
  left: 0;
  top: 0;
  z-index: 0;
}
.change{
  border: none;
}
.change:focus{
  background: #005274;
  border-radius: 10px;
}
.change:focus .cls-1{
  fill: #fff!important;
  stroke: #fff!important;
  border-radius: 10px;
}
.change:focus p{
  color: #fff;
}
.card-body-product{
  position: relative;
  z-index: 1;
  transition: all .3s ease-in;
}
.card-product .cls-1{
  fill: rgba(112, 112, 112, 0.6)!important;
  stroke: rgba(112, 112, 112, 0.6)!important;
}

.card-product:hover .cls-1{
  fill: #fff!important;
  stroke: #fff!important;
}
.card-product p {
  margin-top: 0.4rem;
  color: rgba(112, 112, 112, 0.6);
  font-size: 0.8rem;
  font-weight: 500;
  width: 100%;
  word-wrap: break-word;      /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap;
}

.card-product:hover  img{
  fill: #fff!important;
}
.card-product:hover p{
  color: #fff;
}

.card-1::after{
  opacity: 0;
  // clip-path: circle(10% at 50% 50%);
  transition: all .25s ease-in;
}

.card-1:hover::after{
  opacity: 1;
  // clip-path: circle(100%);
}
.card-1 {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.25s;
}

.card-1:hover {
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}
.card-product-list{
  border: 1px solid #b4b4b4;
  border-radius: 8px;
  margin: 1rem 0.7rem;
}
.body-product{
  padding: 1rem;
}
.bank-icon .icon img{
  height: 5rem;
}
.bank-icon-table {
  overflow: hidden;
  width: 172.27px;
  border: 1px solid #17A9E2;
  border-radius: 10px;
  height: 45px;
}
.bank-icon-table .icon img{
  margin-top: 0px;
  width: 122.27px;
  height: 45px;
 
}
.bank-icon .ratings img{
  height: 1.4rem;
}
.bank-icon .reviews{
  font-size: 0.6rem;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.product .product-name{
  font-size: 1.2rem;
  color: #505050;
  font-weight: bold;
}
.product .product-name label {
  font-size: 1.2rem;
  color: #1cabe6;
  font-weight: bold;
}
.product .title{
  margin: 0.4rem;
  font-size: 0.9rem;
  color: #a3a3a3;
  font-weight: 400;
}
.product .see-detail{
  margin: 1rem 0 0 0;
  text-align: center;
  border-radius: 10px;
}
.product .see{
  height: 100%;
  text-align: center;
}

.product .see .ant-btn {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100px;
  height: 45px;
  flex-grow: 0;
  border-radius: 5px;
  background-color: #1cabe6;
  color: white;
  font-size: 0.9rem;
}
.search-product .ant-input:hover {
  border-color: #A8A8A8!important;
  border-right-width: 1px !important;
  outline: none!important;
}
.search-product .ant-input:focus {
  outline: none;
  box-shadow:none!important;
  border-bottom: 2px solid #A8A8A8;
}
// .search-product .search{
//   outline: none;
//   background: #F6F6F6;
//   border:none;
//   border-bottom: 2px solid #A8A8A8;
//   font-size: 0.8rem;
//   font-weight: 400;
// }

// .search-product .btn-search{
//   color: #A8A8A8;
//   background: #F6F6F6;
//   border:none;
//   border-bottom: 2px solid #A8A8A8;
// }
// .search-product .btn-search:hover{
//   background: #e2e2e2;
// }
// .search-product .btn-search:focus{
//   outline: none!important;
//   box-shadow:none!important;
//   background: #e2e2e2;
// }
.filter-product .btn-sort{
  float: right;
  width: 6rem;
  margin: 1.5rem 0 0 0;
  border: 1px solid #7ebcff;
  background: #fff;
  font-weight: 600;
  color: #1cabe6;
  font-size: 0.7rem;
  border-radius: 0.6rem;
}
.filter-bank .btn-sort{

  margin: 1rem 0 0 0;
  border: 1px solid #7ebcff;
  background: #fff;
  font-weight: 600;
  color: #7ebcff;
  font-size: 0.7rem;
  border-radius: 0.6rem;
}
.pdf-container .pdf-toolbar button{
  color: #1cabe6;
  background-color: #ffffff;
  padding: 0.25rem 0.5rem;
  border: 1px solid #1cabe6  ;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pdf-container .pdf-toolbar button:hover{
  color: #ffffff;
  background-color: #1cabe6;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ffffff  ;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
//Bank
.card-bank-app{
  cursor: pointer;
  width: 100%;
  background: #fff;
  border-radius:0 10px 10px 0;
  display: inline-block;
  margin: 0.5rem ;
  border-radius: 10px;
  position: relative;
}
.card-bank-app .header{
  background: #DFDFDF;
  padding: 0.6rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.card-bank-app .name{
  font-size: 1rem;
  font-weight: 700;
}
.card-bank-app .body{
  padding:1.5rem 1.1rem;
}
.card-icon-bank{
  background: #005274;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  height: 6rem;
  width: 6rem;
  margin: 0 auto;
  padding: 0.7rem  0.4rem 0.5rem 0.4rem;
  position: relative;
}
.card-icon-bank .cls-1{
  fill: #ffffff!important;
  stroke: #ffffff!important;
}
.card-icon-bank p{
  color: #ffffff;
  font-size: 0.6rem;
  font-weight: 600;
}
.card-bank-app .body .text{
  font-size: 0.8rem;
  color: #505050;
  font-weight: 600;
}
.card-bank-app .body .text-value{
  font-size: 0.8rem;
  color: #000000;
  font-weight: 600;
}
.card-bank-app .body .text-status{
  font-size: 0.8rem;
  color: #1cabe6;
  font-weight: 600;
}
.card-bank{
  max-width: 100%;
  max-height: 20rem;
  height: auto;
  margin: 0.5rem;
}
.card-bank .bank-icon{
  margin: 0 0 1rem 0;
}
.card-bank .bank-icon .icon img{
  margin: -2rem 0 0 0;
  max-width: 10rem;
  height: 6rem;
}
.card-bank .bank-icon .ratings{
  margin: -1rem 0 0 0;
}
.card-bank .name{
  font-size: 0.8rem;
  color: #1cabe6;
  font-weight: 600;
}
.card-bank .description{
  min-height: 5rem;
  font-size: 0.6rem;
  color: #0e0e0e;
  font-weight: 600;
}
.card-bank .btn-product{
  color: #1cabe6;
  background: #fff;
  width: 5rem;
  border-radius: 0.6rem;
  font-weight: 700;
  font-size: 0.8rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2)!important;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.detail-bank .avatar{
  width: 12rem;
  height: 100%;
}
.detail-bank .deskripsi{
  font-size: 0.8rem;
  color: #9b9b9b;
  font-weight: 500;
  padding: 0 1rem;
}
// .detail-bank button{
//   font-size: 0.8rem;
//   background: #fff;
//   color: #1cabe6;
//   font-weight: 500;
//   margin: 0.5rem  0.5rem 1rem  0.5rem;
//   border: solid 1px #1cabe6 ;
// }
.bank-icon .name-cabang{
  font-size: 1rem;
  color: #0035e5;
  font-weight: 700;
}
.card-bank .description-cabang{
  font-size: 0.7rem;
  color: #5c6873;
  font-weight: 600;
  height: 5rem;
}
.btn-see{
  font-size: 0.7rem;
  background: #fff;
  color: #1cabe6;
  font-weight: 500;
  margin: 0.5rem  0.2rem 1rem  0.2rem;
  border: solid 1px #1cabe6 ;
}
.detail-bank .line{
  border-bottom: 1px solid #9b9b9b;
  width: 100%;
  margin: 0 0 1rem 0;
}

//detail contact profile
// .detail-contact{
//   background: #ffffff;
//   margin: 1.5rem;
//   padding: 1rem;
// }
// .detail-contact .name{
//   margin-top: 2rem;
//   font-size: 26px;
//   color: #000000;
//   font-weight: 500;
// }
.detail-contact .date{
  font-size: 11px;
  color: #505050;
  font-weight: 500;
}

.detail-contact .edit,
.detail-contact .delete,
.detail-contact .share{
  width: 100%;

}

.detail-contact .edit i,
.detail-contact .delete i,
.detail-contact .share span{
  font-size: 12px;
  font-weight: 500;
}
.detail-contact .action{
  margin: auto 0;
  font-size: 20px;
  color: #999999;
  font-weight: 700;
}
// .detail-contact .information,
// .detail-contact .contact{
//   margin-top: 2rem;
//   font-size: 13px;
//   color: #999999;
//   font-weight: 700;
// }
// .detail-contact .phone,
// .detail-contact .email{
//   margin: 0.1rem;
//   font-size: 12px;
//   color: #000000;
//   font-weight: 700;
// }

.pb-0 .bottom .line {
  color: #FAFAFA;
  padding: 0.6rem;
  border-left: 4px solid rgba(0,0,0,0.15);
  border-bottom: 1px solid rgba(0,0,0,0.15);
  background: rgba(255, 255, 255, 0.15);
}
//cards contact
.center {
  position: absolute;
  top: 50%;
  left: 50%;
 // -webkit-transform: translate(-50%, -50%);
}
.cards {
  width: 100%;
  height: 110px;
  background-color: #fff;
  background: linear-gradient(#f8f8f8, #fff);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  margin-top:0.5rem;
}

// .cards .additional {
//   padding: 0.5rem  0  0.5rem  0;
//   position: absolute;
//   width: 140px;
//   height: 100%;
//   background: linear-gradient(#dfdfdf, #dfdfdf);
//   transition: width 0.4s;
//   overflow: hidden;
//   z-index: 2;
// }
.cards .general .name{
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8)
}
.cards .additional .user-cards {
  height: 100%;
  position: relative;
  float: left;
}

.cards .additional .user-cards .points-nonactive {
  top: 10%;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.70em;
  font-weight: bold;
  background: #ce2828;
  padding: 0.125rem 0.75rem;
  white-space: nowrap;
  top: 90%;
}
.cards .additional .user-cards .points {
  top: 10%;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.70em;
  font-weight: bold;
  background: #3cb46e;
  padding: 0.125rem 0.75rem;
  white-space: nowrap;
}

.cards .additional .user-cards .points {
  top: 90%;
}

// .cards .general {
//   margin-left: 140px;
//   height: 100%;
//   top: 0;
//   z-index: 1;
//   background-color: #FAFAFA;
//   color: #797979;
//   box-sizing: border-box;
//   padding: 1rem;
// }

.cards .general .more {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.9em;
}



.avatar-contact {
  border: 2px solid #cacaca;
  width: 70px;
  height: 70px;
  border-radius: 50rem;
}
.avatar-contact .img-avatar{
  margin: 0 auto;
  border: 2px solid #cacaca;
  width: 70px;
  height: auto;
  clear: both;
}
.avatar-profile {
  border-radius: 100%;
  overflow: hidden;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.avatar-profile .img-avatar{
  margin: 0 auto;
  border: 2px solid #cacaca;
  width: 150px;
  height: 150px;
  clear: both;
}
//form-document
.form-document {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0rem 0rem 1rem -0.7rem;
  line-height: 1.5;
  color: #5c6873;
  background-color: transparent;
  background-clip: padding-box;
  border: transparent;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//Navbar
.app-header{
  background-color:#f6f6f6 ;

  border-color: #f6f6f6;
}
//Body
.app-body{
  background-color: #ECF0F5;
}
//footer
.app-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
  color: #23282c;
  background: #c0e4ff;
  border-top: 1px solid #1cabe6;
}
//Sidebar
.sidebar{
  border-color: #1cabe6;
  background-color:#ffffff ;
  height: 100%!important;
  padding: 0 0.3rem;
}

.sidebar .nav {
  min-height: 80%!important;
  width: 190px!important;
}
.sidebar .nav-item{
  margin-top: 0.2rem;
}
.sidebar > .sidebar-nav {
  margin-top: 2rem;
}
.sidebar .nav-link .nav-icon {
  color: #000000;
  font-weight: 600;
}
.sidebar .nav-link {
  color: #B0B0B0;
  font-weight:500;
}
.sidebar .nav-link.active,
.sidebar .nav-link:hover,
.sidebar .nav-link:hover .nav-icon,
.sidebar .nav-link.active .nav-icon {
  background:#1cabe6 ;
  color: #FFFFFF;
  border-radius : 0.6rem;
}
.sidebar .sidebar-minimizer,
.sidebar .sidebar-minimizer::before,
.sidebar .sidebar-minimizer:hover{
  background-color: #1cabe6 ;
}

.sidebar .nav-dropdown.open {
  background-color: #FFFFFF;
}

.sidebar .nav-dropdown.open .nav-icon {
  color: #000000;
  font-weight: 600;
}

.sidebar .nav-dropdown.open .nav-link {
  color: #B0B0B0;
  font-weight: 500;
}
.sidebar .nav-dropdown.open .nav-link.active {
  color: #FFFFFF;
  font-weight: 500;
}
//font
// h1,h2,S

.tab-name{
  font-size: 1.3;
  color: #797979;
  font-weight: 600;
}
.list-name{
  font-size: 1.2rem;
  color: #1cabe6;
  font-weight: 600;
}
.list-position,
.list-category{
  font-size: 0.8rem;
  color: #797979;
  position: relative;
  display: inline-block;
}
.f-name{
  padding-top: 1.3rem;
  margin-left: 0.8rem;
  font-size: 2rem;
  font-weight: 700;
  color:#7ebcff ;
}
.f-office{
  margin-top: -0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color:#7ebcff ;
}
.f-email{
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color:#7ebcff ;
}
.f-phone{
  font-size: 1rem;
  font-weight: 600;
  color:#7ebcff ;
}
.f-jk{
  padding-top: 1rem;
  margin-top: 0.7rem;
  margin-left: 0.6rem;
  font-weight: 900;
  font-size: 1rem;
  color:#7ebcff ;
}
.edit-font{
  font-size: 0.9rem;
  font-weight: 700;
  color: #A8A8A8;
}
//Error Login

.invalid-feedback{
  display: block;
  width: 100%;
  font-size: 80%;
  color: #f86c6b;
}
.step-branch{
  max-width: 100%!important;
}
.mt{
  margin-top: 1rem!important;
}
@media (max-width: 575.98px) {

.containers{
  margin: 0rem;
}
.detail-contact{
  background: #ffffff;
  margin: 0.5rem;
  padding: 0.5rem;
}
.detail-contact .edit i,
.detail-contact .delete i,
.detail-contact .share span{
  font-size: 12px;
  font-weight: 500;
}
//detail profil office
.rbc-toolbar .rbc-toolbar-label {
  width:100%;
  margin : 0.5rem 0 !important;
}
.animation:before {
  width: 800px;
  height: 1000px;
  transform: translateX(-50%);
  left: 30%;
  bottom: 40%;
  right: initial;
  top: initial;
  transition: 2s ease-in-out;
}
.animation:before {
  width: 1700px;
  height: 2000px;
  transform: translateX(-50%);
  left: 30%;
  bottom: 30%;
  right: initial;
  top: initial;
  transition: 2s ease-in-out;
}
.card-detail-office .description .isi-header{
  margin: 0.5rem;
  font-size: 1.1rem;
  text-align: center;
}
.card-detail-office .description .isiApp,
.card-detail-office .description .isi{
  margin: 0.5rem;
  font-size: 0.7rem;
  font-weight: 800;
}
.card-detail-office .description .isi span{
  font-size: 0.7rem;
  font-weight: 600;
}
.card-detail-office .description .isiApp span{
  font-size: 0.7rem;
  font-weight: 600;
}
//dashboard
.card-dashboard .das-profil .lines{
  padding-bottom: 0.4rem;
  border-right: none;
  border-bottom: solid 1px #cecece;
}
.das-profil .left .img-avatar {
  width: 3rem;
  height: 3rem;
}
.das-profil .welcome{
  display: block;
  margin:0.1rem 0 0 0!important;
  font-size: 10px;
  font-weight: 500;
}
.das-profil .name{
  margin-bottom: 0!important;
  font-size: 13px;
  font-weight: 500;
}
.das-profil .location{
  margin-bottom: 0!important;
  font-size: 11px;
  font-weight: 500;
}
.das-profil .email,
.das-profil .phone {
  padding-left: 0rem;
  margin-bottom: 0 !important;
  font-size: 11px;
  font-weight: 500;
}
.mt{
  margin-top: 0rem!important;
}
.das-profil .edit-profil{
  margin: -1.7rem 0 0 0!important;
}
.das-profil {
  padding:0.5rem 1.3rem;
}
.card-dashboard{
  max-height: 600px;
}
.card-target {
  height: 800px;
}
.card-progress {
  margin: 1rem 0 0 0;
  height: auto;
}
.card-progress .headers{
  font-size: 1rem;
  font-weight: 600;
}
.card-progress .card-pro {
  max-width: 200px;
  margin: auto;
  margin-top: 0.5rem;
}
.card-dashboard .card-body .names{
  font-size: 0.8rem;
}
.notification p{
   font-size: 0.9rem;
}

// .das-profil .calender label,
// .das-profil .calender p{
//   font-size: 0.8rem;
// }
.update{
  font-size: 0.8rem;
  margin-left: 0.4rem;
  margin-top: -0.3rem;
}
.card-slider{
  width: 100%!important;
}
.card-note{
    font-size: 0.6rem;
  }
  .card-note .note-name{
    margin-top: 0.6rem;
    font-size: 0.7rem;
    font-weight: 700;
  }
  .card-note .note-isi{
    margin-top: -0.5rem;
    font-size: 0.7rem;
    font-weight: 700;
  }

// .card-req{
//   font-size: 0.6rem;
// }
.card-req .req-body .req-isi{
  font-size: 0.8rem;
}
.card-req .req-body .logo{
  text-align: center;

}
.card-req .req-body .desc{
  margin-top: 1rem;
  font-size: 0.8rem;
  text-align: center;

}
.card-note .note-body .note-isi{
  font-size: 0.8rem;
}
.user-cards .avatar-contact .img-avatar{
  height: 100%;
  width: 100%;
}

.cards{
  height: 140px;
}
  .cards .additional {
    width: 100px;
  }
  .cards .general .name{
    margin-top: 0.2rem;
    text-transform: uppercase;
    font-size: 0.7rem;
  }

  .cards .general .email{
    font-size: 0.7rem;
  }

  .cards .additional .user-cards .points,
  .cards .additional .user-cards .points-nonactive {
    font-size: 0.50em;
  }

  .cards .additional .user-cards .points,
  .cards .additional .user-cards .points-nonactive {
    top: 70%;
  }

  .cards .general {
    margin-left: 90px;
    height: 100%;
  }

.cards .general .name{
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
}
  .cards .general .more {
    font-size: 0.5em;
  }
  .card-dashboard .names{
    font-size: 0.8rem;
  }
  .card-dashboard .number{
    font-size: 0.8rem;
  }
  .card-dashboard .das-profil .text-bar{
    font-size: 2rem;
  }
  .card-dashboard .das-profil{
    padding: -2rem!important;
  }
  .card-dashboard .das-profil .text-label{
    font-size: 0.8rem;
    font-weight: 700;
  }
  .das-profil .edit-target{
    margin:0.5rem!important;
    position: absolute;
    right: 0;
    top: 0;
  }
  .card-dashboard .params{
    padding: 1rem;
  }
  .card-dashboard .das-profil .text-bar{
    font-size: 1rem;
  }
  .container-product .card-product{
    height: 6rem;
  }
  .container-product .card-product .card-body-product svg{
    height: 2rem;
  }
  .container-product .card-product .card-body-product p{
    font-size: 0.6rem;
  }
  .container-product{
    margin-left: -1rem;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
    grid-gap: 0.5rem;
  }
  .product .product-name{
    font-size: 1rem;
  }
  .product span{
    font-size: 0.8rem;
  }
  .product .see .see-detail{
    font-size: 0.8rem;
  }
  .product .use p{
    font-size: 0.5rem;
  }
  .filter-product .btn-sort{
    width: 4.5rem;
    font-size: 0.6rem;
  }
  .filter-bank .btn-sort{
    float: right;
    width: 4.5rem;
    margin: 1.5rem 0 0 0;
    border: 1px solid #7ebcff;
    background: #fff;
    font-weight: 600;
    color: #1cabe6;
    font-size: 0.6rem;
    border-radius: 0.6rem;
  }
  .steps-action .prev span{
    font-size: 0.6rem;
    margin-left: -0.3rem!important;
  }
  .steps-action .next{
    font-size: 0.6rem;
  }
  .steps-action .steps-num{
    font-size: 0.5rem;
    margin-top: 0.5rem;
  }
  .steps-action .steps-sub{
    font-size: 0.9rem;
    color:  #000000;
    font-weight: 700;
  }
  .steps-action .steps-desc{
    font-size: 0.6rem;
    font-weight: 600;
    color: #A8A8A8;
  }
  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin:0rem!important;
  }
  .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon{
    margin:0rem!important;
  }
  .ant-steps-item-container {
  width: 0rem!important;
  }
  .ant-steps-item-process  .ant-steps-item-container  .ant-steps-item-content  .ant-steps-item-title {
    display: none;
  }
  .ant-steps-item-wait .ant-steps-item-container .ant-steps-item-content{
    display: none;
  }
  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content{
    display: none;
  }
  .step-branch{
    max-width: 18%!important;
    margin-left: 0rem!important;
  }

  .job-overview-buttons .search-buttons {
    font-size:0.7rem;
  }

  .overview-detail .job-card-title,
  .overview-detail .job-card-subtitle {
    font-size:0.8rem;
  }
  .hide-md{
    display: none;
  }
  .main .container-fluid {
    padding: 0rem 10px 10px 10px ;
  }
  .profile .name{
    font-size: 15px;
    margin-bottom: 0.7rem;
  }
  .profile .btn-profile{
    font-size: 10px;
  }
  .line-right{
    border-right: none;
  }
  .breadcrumb {
    display: none;
  }

  .btn-edit{
    margin: 0 0 0 0;
  }

  .f-j-sm{
    font-size: 0.6rem;
  }
  .f-sm{
    font-size: 0.55rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

  .animation:before {
    width: 1500px;
    height: 2000px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 40%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
  .animation:before {
    width: 1500px;
    height: 2000px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 40%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
  .das-profil .welcome{
    padding-left: 0.8rem;
  }
  .das-profil .name{
    font-size: 1rem;
    padding-left: 1rem;
  }
  .das-profil .location{
    font-size: 0.8rem;
    padding-left: 1rem;
  }
  .das-profil .email,
  .das-profil .phone {
    padding-left: 0rem;
    font-size: 0.8rem;
    padding-left: 1rem;
  }
  .card-dashboard .number {
    margin-top: 0rem!important;
  }
  .card-dashboard .das-profil .left img {
    width: 4rem!important;
    height: 4rem!important;
  }
  .step-branch{
    max-width: 18%!important;
  }
  .card-dashboard .names{
    font-size: 0.9rem;
  }
  .card-dashboard .number{
    font-size: 0.8rem;
    margin-top: -3rem;
  }
  .card-dashboard .notification p{
    margin-bottom: 2rem;
  }
  .card-dashboard .params{
    padding: 1.7rem;
  }
  .card-dashboard .das-profil .text-label{
    margin-top: 1rem;
  }

  .card-req .req-body .logo{
    text-align: center;

  }
  .card-req .req-body .desc{
    margin-top: 1rem;
    font-size: 0.8rem;
    text-align: center;

  }
  .cards .general .name{
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;
  }
  .steps-action .steps-sub{
    font-size: 1rem;
    color:  #000000;
    font-weight: 700;
  }
  .steps-action .step
  .steps-action .prev span{
    font-size: 0.8rem;
    margin-left: -0.3rem!important;
  }
  .steps-action .next{
    font-size: 0.8rem;
  }
  .steps-action .steps-num{
    font-size: 0.5rem;
    margin-top: 0rem;
  }
  .ant-steps{
    margin-left: -9%!important;
  }

  .ant-steps-item-process  .ant-steps-item-container  .ant-steps-item-content  .ant-steps-item-title {
    display: none;
  }
  .ant-steps-item-wait .ant-steps-item-container .ant-steps-item-content{
    display: none;
  }
  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content{
    display: none;
  }
  .hide-md{
    display: none;
  }

 .card-promo{
  height: 180px;
  }
  .wrapper .react-multi-carousel-list{
    height: 300px;
  }
  .react-multiple-carousel__arrow {
    margin-top: -4rem;
  }
  .main .container-fluid {
    padding: 0rem 10px 10px 10px ;
  }
  .breadcrumb {
    display: none;
  }
  .faq-edit{
    display: none;
  }
  .faq-action{
    display: block;
  }
 
}
@media (min-width: 768px) and (max-width: 991.98px) {
 
  .card-dashboard .names{
    font-size: 0.8rem;
    font-weight: 400;
  }
  .card-dashboard .number{
    font-size: 0.7rem;
  }
  .card-dashboard .params{
    padding: 2rem;
  }
  .card-dashboard .text-label{
    margin-top: 2rem;
  }
  .ant-steps{
    margin-left: -7%!important;
  }
  .hide-md{
    display: none;
  }

  .card-req .req-body .logo{
    text-align: center;

  }
  .card-req .req-body .desc{
    font-size: 0.8rem;
    padding-left: 3rem;
    text-align: left;

  }

  .das-profil .welcome{
    padding-left: 1rem;
  }
  .das-profil .name{
    font-size: 1.1rem;
    padding-left: 1rem;
  }
  .das-profil .location{
    font-size: 0.8rem;
    padding-left: 1rem;
  }
  .das-profil .email,
  .das-profil .phone {
    padding-left: 0rem;
    font-size: 0.8rem;
    padding-left: 1rem;
  }
  .das-profil .btn{
    margin: -0.5rem 0 0 0!important;
  }

  .card-promo{
    height: 180px;
  }

  .main .container-fluid {
    padding: 0rem 15px 15px 15px ;
  }
  .breadcrumb {
    display: none;
  }
  .faq-action{
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {

  .das-profil .welcome{
    padding-left: 1rem;
  }
  .das-profil .name{
    font-size: 1.1rem;
    padding-left: 1rem;
  }
  .das-profil .location{
    font-size: 0.8rem;
    padding-left: 1rem;
  }
  .das-profil .email,
  .das-profil .phone {
    padding-left: 0rem;
    font-size: 0.8rem;
    padding-left: 1rem;
  }
  .card-dashboard .params{
    padding: 2rem;
  }
  .card-dashboard .text-label{
    margin-top: 2rem;
  }

  .ant-steps{
    margin-left: -5%!important;
  }
  .hide-md{
    display: none;
  }

 .card-promo{
  height: 200px;
  }

  .card-req .req-body .logo{
    text-align: center;

  }
  .card-req .req-body .desc{
    font-size: 0.8rem;
    padding-left: 3rem;
    text-align: left;
  }
  .faq-action{
    display: none;
  }

}
@media (min-width: 992px){
  .header-fixed .app-body {
      margin-top: 0px;
  }
 
}
@media (max-width: 991.98px){
  .app-header .navbar-brand {
      left: 0%;
      margin-left: 0px;
  }
 
}
@media (min-width: 1200px) {
  .card-dashboard .params{
    padding: 2rem;
    margin-bottom: 2rem;
  }
  .card-dashboard .notification p {
    margin :-1rem 0 1rem 0;
  }
  .card-dashboard .sub-card{
    margin: 0.5rem -1rem 0 -1rem
  }
  .card-dashboard .sub-card i {
    font-size: 1rem;
  }
  .card-dashboard .sub-card .group{
    font-size: 0.6rem;
  }
  .card-dashboard .sub-card .action{
    font-size: 0.5rem;
  }
  .card-progress .card-pro{
    max-width: 40%;
    margin: 1rem 0 0 30%;
  }
  .faq-action{
    display: none;
  }
}
//faq
.faq h1{
  font-weight: 700;
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #005274;
    margin-bottom: -10px;
}
.faq span{
  font-weight: 400;
    font-size: 16px;
    //font-stretch: normal;
    //font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: rgb(182, 179, 179);
   // margin-top: -20px;
   // margin-bottom: -45px;    
}
.faq button{
  margin-bottom: -150px;  
border:none;
background-color: #f6f6f6!important;
}
.faq p:hover {
  text-decoration: underline;
  text-decoration-color:rgb(104, 102, 102) ;
  color: rgb(104, 102, 102);
  transition: 0.5s ease;
}
.accordion {
  border: 5px solid #0e0e0e;
}
.faq-wrapper {
  display: flex;
  justify-content: center;
}
// .faq-accordion {
//   width: 80%;

// }
.faq-accordion-item {
   margin-bottom: 10px;
   padding: 4px 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    border: 1px solid #ccc;
}
.faq-accordion-item ul{
    list-style-type: '- ';
    padding:0px;
    margin-bottom: 0px;
}
.faq-accordion-item ul li{
 
  margin-left: 15px;
  
}
.faq-wrap {
  display: flex;
  justify-content: space-between;
 
}
.faq-title {
display:flex;
justify-content: space-between;
text-decoration: none;
align-items: center;
padding-top: 10px;
cursor: pointer;
width: 85%;

}
.faq-title h2{
  color: #0056b3;
  text-decoration: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.faq-wrap span{
  color: #0056b3;
  text-decoration: none;
  background-color: transparent;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 8px;
}
.faq-title:hover {
  text-decoration: underline;
  text-decoration-color:#0056b3 ;
  transition: 0.5s ease;
}

.faq-content{
  color: #1cabe6;
  max-height: 0;
  overflow: hidden;
}
.faq-content.show{
  height: auto;
  max-height: 9999;

}
.faq-content-text{
  text-align: justify;
  font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #505050;
}


//New Style REVAMP---------------------------------------

.main .container-fluid {
  padding: 4.5rem 15px 15px 15px ;
}

.row-top {
  background: white;
  margin-top: -0.5rem;
}

@media (max-width: 991px) {
  .row-top {
    margin-top: -4.5rem;
  }
}

.row-top__contact-list {
  margin-top: -0.7rem;
}

@media (max-width: 991px) {
  .row-top__contact-list {
    margin-top: -4.5rem;
  }
}

.app-add-edit {
  padding-top: 1.5rem !important;
}

.card-dashboard {
  cursor: pointer;
  position: relative;
  background: #fff;
  border-radius: 8px;
  transition: all .3s;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
}

.icon__profile {
  background: #E2F0FF;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #17A9E2 !important;
}

.icon__dashboard--blue {
  color: #17A9E2 !important;
  background: #E2F0FF;
  padding: 0.5rem;
  border-radius: 50%;
}

.icon__dashboard--orange {
  color: #ff771d !important;
  background: #ffecdf;
  padding: 0.5rem;
  border-radius: 50%;
}

.icon__dashboard--green {
  color: #2eca6a !important;
  background: #e0f8e9;
  padding: 0.5rem;
  border-radius: 50%;
}

.icon__dashboard--purple {
  color: #4154f1 !important;
  background: #e2e2ff;
  padding: 0.5rem;
  border-radius: 50%;
}

.icon__dashboard--red {
  color: #dc3545 !important;
  background: #ffe2e5;
  padding: 0.5rem;
  border-radius: 50%;
}

// .icon-telp__dashboard {
//   color: #17A9E2 !important;
//   background: #E2F0FF;
//   padding: 0.2rem;
//   border-radius: 50%;
// }

.dashboard-timeline__circle {
  width: 1rem;
  height: 1rem;
  min-height: 1rem;
  border-radius: 50%;
}

.dashboard-timeline__square {
  width: 0.2rem;
  height: 100%;
  min-height: 2rem;
  background: #6465674D;
}

.notification .sub-card{
  cursor: pointer;
  margin-top: -1rem;
  padding: 0.5rem 1rem;
  position: relative;
  max-height: 400px;
  transition: all .3s;
  background: transparent;
}

.card-profile {
  border-radius: 0px !important;
}

.search-product .search{
  outline: none;
  background: white;
  border:none;
  font-size: 0.8rem;
  font-weight: 400;
  border-radius: 4px 0 0 4px;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
}

.search-product .btn-search{
  color: #A8A8A8;
  background: white;
  border:none;
  border-radius: 0 4px 4px 0;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
}

.search-product .btn-search:hover{
  background: #e2e2e2;
}

.search-product .btn-search:focus{
  outline: none!important;
  box-shadow:none!important;
  background: #e2e2e2;
}

.faq-accordion {
  width: 96%;
}

.blue-button {
  color: white !important;
  background-color: #17A9E2 !important;
  border: none;
}

.blue-button:hover {
  background-color: #0778a5;
}

.dark-blue-button {
  color: white !important;
  background-color: #1E5870 !important;
  border: none;
}

.active-contact{
  cursor: pointer;
  margin: 0.3rem 0rem;
  min-height: 6rem;
  border-radius: 8px;
  border: solid 1px #dfdfdf;
  background-color: #fff
}

.page-link {
  color: #A8A8A8;
}

.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #e4e7ea !important;
}

.detail-contact .information,
.detail-contact .contact{
  margin-top: 2rem;
  font-size: 13px;
  font-weight: 700;
  background: #EBEBEB;
  color: #141414;
  font-weight: 700;
  border-radius: 8px 8px 0 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.detail-contact .phone,
.detail-contact .email{
  padding-bottom: 4px;
  padding-top: 4px;
  font-size: 12px;
  color: #000000;
}

.detail-contact__right-border {
  border-right: 1px solid #EBEBEB;
}

@media (max-width: 1280px){
  .detail-contact__right-border {
      border-right: none;
  }
}

.detail-contact__row {
  border-left: 1px solid #EBEBEB;
  border-right: 1px solid #EBEBEB;
}

.detail-contact__bottom {
  border-bottom: 1px solid #EBEBEB;
}

//Background
.red-button {
  color: white !important;
  background-color: #FF0C00 !important;
  border: none;
}

.red-button:hover {
  background-color: #850b04 !important;
}

//Application halaman Contact Detail
.card-req {
  border-radius: 8px;
}

.card-req .req-body {
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
}

.ant-tabs-nav-wrap {
  background: white;
  margin: 0 -2rem;
}

//detail contact profile
.detail-contact{
  background: transparent;
  margin: 1.5rem;
  padding: 1rem;
}

@media (max-width: 768px){
  .detail-contact{
    margin: 0;
    padding: 0;
  }
}

.detail-contact__card {
  background: white; 
  border-radius: 8px;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
}

.detail-contact__tittle {
  background: #EBEBEB;
  color: #141414;
}

.detail-contact .name{
  font-size: 26px;
  color: #000000;
  font-weight: 500;
}

//Application add
.app-add__steps {
  background: white;
}

@media (min-width: 1280px){
  .app-add__steps {
      margin-left: -3rem;
  }
}

@media (max-width: 768px){
  .app-add__steps {
      display: none;
  }
}

.alamat-add__tittle {
  background: #005274;
  color: white;
}

.alamat-add__row {
  border-left: 1px solid #EBEBEB;
  border-right: 1px solid #EBEBEB;
}

.alamat-add__bottom {
  border-bottom: 1px solid #EBEBEB;
}

.upload-document__collapse .ant-collapse-header {
  color: white !important;
  align-items: center !important;
  background: #005274;
}

.upload-document__button {
  border: 1px solid #ebebeb;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.app-add__bank-card {
  border: 1px solid #EBEBEB !important;
}

.app-add__bank-card:hover {
  border: 1px solid #17A9E2 !important;
}

//summary apps

.card__summary {
  max-width: 100%;
  max-height: 18rem;
  min-height: 18rem;
  margin: 0.5rem;
}

.card__summary .name-of-bank , .card-bank .name-of-bank {
  color: #1cabe6;
  font-size: 1.2rem;
}

.card__summary .name-branch, .card-bank .name-branch {
  color: #0035e5;
  margin-bottom: 1rem;
}

.card__summary .name-of-bo, .card-bank .name-of-bo {
  font-size: 0.9rem;
  font-weight: 400;
  color: #141414;
}

//Application edit
.app-edit__steps {
  margin-left: -1rem;
  background: white;
}

@media (min-width: 1280px){
  .app-edit__steps {
      margin-left: -3rem;
      margin-right: -3rem;
  }
}

@media (max-width: 768px){
  .app-edit__steps {
      display: none;
  }
}

@media (max-width: 768px){
  .app-edit__tittle {
    margin-top: -5rem;
  }
}

//PIPELINE
.pipeline__period-select {
  border: 1px solid rgba(20,20,20, .3);
  border-radius: 4px;
}

.card-credit-score{
  padding: 23px 32px 35px;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(223, 223, 223, 0.5);
  background-color: #fff;
}

@media (max-width: 768px){
  .card-credit-score{
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(223, 223, 223, 0.5);
    background-color: #fff;
  }
}

@media (max-width: 1199px){
  .icon-rotate--90 {
    transform: rotate(90deg);
  }
}

.card-credit-score .data-table-extensions {
  padding-left: 0;
  padding-right: 0;
}

.card-credit-score .data-table-extensions-filter {
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid #ebebeb;
  padding: 0 4px;
  border-radius: 4px;
  width: 50%;
  display: flex;
}

.card-credit-score .filter-text {
 display: block;
 width: 100%;
}

.card-credit-score .icon {
  float: none;
  display: block;
  width: 20px;
  margin-top: 0.5rem;
}

.card-credit-score .download {
  background-image: url("../assets/icon/icon download.svg") !important;
  width: 48px !important;
  height: 48px !important;
  border: 1px solid #1cabe6 !important;
}

.card-credit-score .print {
  background-image: url("../assets/icon/icon printer.svg") !important;
  width: 48px !important;
  height: 48px !important;
  border: 1px solid #1cabe6 !important;
}

.custom-ant-table th {
  font-weight: 700 !important;
  background: #EBEBEB !important;
  border: 1px solid #C1C1C1 !important;
}

.invoice-btn__admin_branch {
  color: #1cabe6;
  background: white;
  border: 1px solid #1cabe6;
}

.cards .additional {
  padding: 0.5rem  0  0.5rem  0;
  position: absolute;
  width: 80px;
  height: 100%;
  background: #c1c1c1;
  transition: width 0.4s;
  overflow: hidden;
  z-index: 2;
}

.cards .general {
  margin-left: 80px;
  height: 100%;
  top: 0;
  z-index: 1;
  background-color: #FAFAFA;
  color: #797979;
  box-sizing: border-box;
  padding: 1rem;
}

.card-dashboard-bank div {
  font-size: 16px;
}

.card-dashboard-bank strong {
  font-size: 24px;
  color: #1cabe6;
  text-align: center;
}

.dashboard-bank-table th {
  font-weight: 700 !important;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  border: 0px;
  border-radius: 2px;
}

.view-app__collapse .ant-collapse-header {
  color: #141414 !important;
  align-items: center !important;
  background: #EBEBEB;
}

.pool-table th {
  font-weight: 700 !important;
  background: #EBEBEB !important;
}

.popup__bank-detail {
  max-height: fit-content;
  min-height: fit-content;
}

.edit-contact label, .product-add label {
  font-size: 16px !important;
  font-weight: 700;
}

.ant-breadcrumb-link svg {
  margin-bottom: 0.5rem;
}

.header-title {
  font-size: 24px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  margin-top: 1rem;
}

.product-type-modal {
  max-width: 800px !important;
}

.select-bank-type .ant-select-arrow {
  margin-top: -0.5rem;
}

.selected-adviser {
  background-color: #cccccccc !important;
}

.c-yellow {
  color: #FFD335!important
}

.border--marketing-tool {
  border-right: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
}

.business-card--top {
  width: 9cm;
  height: 5.5cm;
  background-image: url("../assets/img/marketing/business-card-top-rev.jpg");
  background-size: cover;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 1.7cm;
}

.business-card--bot {
  width: 9cm;
  height: 5.5cm;
  background-image: url("../assets/img/marketing/business-card-bot-rev.jpg");
  background-size: cover;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 2cm;
}

.envelope--template-1 {
  width: 22cm;
  height: 11cm;
  background-image: url("../assets/img/marketing/envelope-background-1.jpg");
  background-size: cover;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 2cm;
}

.flyer-print--template-1-top {
  width: 15cm;
  height: 21cm;
  background-image: url("../assets/img/marketing/flyer-print-background-1-top.png");
  background-size: cover;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 2cm;
}

.flyer-print--template-2-top {
  width: 15cm;
  height: 21cm;
  background-image: url("../assets/img/marketing/flyer-print-background-2-top.png");
  background-size: cover;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 2cm;
}

.flyer-print--template-1-bot {
  width: 15cm;
  height: 21cm;
  background-image: url("../assets/img/marketing/flyer-print-background-1-bot.png");
  background-size: cover;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 2cm;
}

.stamp--template-1 {
  width: 21cm;
  height: 7cm;
  background-image: url("../assets/img/marketing/stamp-background-1.jpg");
  background-size: cover;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 2cm;
}

.rollup-banner--template-1 {
  width: 6cm;
  height: 16cm;
  background-image: url("../assets/img/marketing/roll-up-banner-background-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 2cm;
}

.or-divider {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 

.or-divider span { 
   background:#fff; 
   padding:0 10px; 
}

.instagram-template .ant-upload-list{
  display: block;
}

.maintenance-color {
  background-color: #f3ec78;
  background: linear-gradient(90deg, #feac5e, #c779d0, #4bc0c8);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: fit-content;
}

.maintenance-color h1 {
  font-size: 12rem;
}

@media (max-width: 768px) {
  .maintenance-color h1 {
    font-size: 8rem;
  }
}

.maintenance-text {
  font-size: 2rem;
}

.dark-blue-button {
  color: white !important;
  background-color: rgb(1, 53, 92) !important;
  border: none;
}

.green-button {
  color: white !important;
  background-color: rgb(77, 189, 116) !important;
  border: none;
}

.ticket-dashboard-table .ant-table-thead > tr > th {
  background: #EBEBEB;
}

.ticket-dashboard-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #EBEBEB;
}

.project-developer-form .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.project-developer-form input, .project-developer-form textarea {
  color: #141414 !important;
}